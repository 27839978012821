import {derived} from 'svelte/store'
import {pageWidth} from "$lib/stores/ui/pageWidth.js";

export const device = derived(pageWidth,
    $pageWidth => {
        if($pageWidth > 1000) {
            return 'desktop'
        }else {
            return 'mobile'
        }
    }
)